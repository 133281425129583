import { Model } from '~/lib/data/mod.js';

/**
 * A physical address data structure
 *
 * @typedef {Object} PhysicalAddress
 * @prop {string} [name=''] An optional name for the location
 * @prop {string} street1 The first street address line
 * @prop {string} [street2] The optional second street address line, usually an unit number
 * @prop {string} locality The city
 * @prop {string} region The state or province
 * @prop {string} postalCode The postal code for the address
 */

/**
 * Model for physical address data
 *
 * @param {Object} data The raw physical address data
 * @returns {PhysicalAddress}
 */
export const PhysicalAddress = Model.extend(
  function PhysicalAddress({ name = '', street1, street2, locality, region, postalCode }) {
    return { name, street1, street2, locality, region, postalCode };
  },
);
