import { Model } from "~/lib/data/mod.js";
import { convertIso8601UtcToLocalDate, CalendarDate } from "~/lib/datetime/mod.js";
import { PhysicalAddress } from "~/features/Location/mod.js";

import { EventSession } from "./EventSession.js";

/**
 * Data structure for the main event
 *
 * @typedef {Object} Event
 * @prop {string} [_id] The record ID
 * @prop {string} title The event title
 * @prop {Date} startDate The start date for the event in UTC time
 * @prop {string} startDateYmd The start date for the event as YYYY-MM-DD
 * @prop {Date} endDate The end date for the event in UTC time
 * @prop {string} endDateYmd The end date for the event as YYYY-MM-DD
 * @prop {import('~/features/Location/mod.js').PhysicalAddress} address The event location info
 * @prop {import('./EventSession.js').EventSession[]} sessions The list of event sessions
 * @prop {(this: Event) => string} getEventYear Get the year representation of the event. Either
 *       YYYY if the event starts and ends in the same year or YYYY-YYYY if the event spans the year
 *       transition.
 * @prop {(this: Event) => Date[]} getEventDates
 * @prop {(this: Event) => Date} getLocalStartDate
 * @prop {(this: Event) => Date} getLocalEndDate
 *
 * The Event constructor
 *
 * @typedef {(data: Object) => Event} EventConstructor
 * @prop {(data: Object) => Event} fromSanityRecord Convert raw data from Sanity.io API to Event instance
 */

const structuredSessions = Symbol("Event.structuredSessions");

const structureSessions = (sessions = []) => {
  const structuredObj = sessions.reduce((s, session) => {
    const inst = EventSession(session);
    const groupKey = inst.startDateYmd;
    if (!s[groupKey]) {
      s[groupKey] = [];
    }
    s[groupKey].push(inst);
    return s;
  }, {});
  const structured = [];
  for (const dateYmd in structuredObj) {
    if (Object.hasOwn(structuredObj, dateYmd)) {
      structured.push([
        dateYmd,
        convertIso8601UtcToLocalDate(dateYmd),
        structuredObj[dateYmd],
      ]);
    }
  }
  structured[structuredSessions] = true;
  return structured;
};

/**
 * Model for event data
 *
 * @type {EventConstructor}
 */
export const Event = Model.extend(
  /** @constructs Event */
  function Event({
    _id,
    title,
    startDate,
    endDate,
    address = {},
    sessions = [],
  }) {
    return {
      _id,
      title,
      startDate: new Date(startDate),
      startDateYmd: startDate,
      endDate: new Date(endDate),
      endDateYmd: endDate,
      address: PhysicalAddress(address),
      sessions: sessions[structuredSessions]
        ? sessions
        : structureSessions(sessions),
    };
  },
  {
    proto: {
      /**
       * @todo convert to getter?
       * @this {Event}
       */
      getEventYear() {
        const startYear = this.startDate.getFullYear();
        const endYear = this.endDate.getFullYear();

        if (startYear === endYear) {
          return String(startYear);
        }

        return `${startYear}-${endYear}`;
      },
      /**
       * @this {Event}
       */
      getEventDates() {
        const localStartDate = CalendarDate(this.startDateYmd);
        const localEndDate = CalendarDate(this.endDateYmd);
        const eventDates = [];
        for (
          let currentDate = localStartDate;
          currentDate <= localEndDate;
          currentDate = currentDate.addDay(1)
        ) {
          eventDates.push(currentDate.toDate());
        }
        return eventDates;
      },
      /**
       * @this {Event}
       */
      getLocalStartDate() {
        return CalendarDate(this.startDateYmd).toDate();
      },
      /**
       * @this {Event}
       */
      getLocalEndDate() {
        return CalendarDate(this.endDateYmd).toDate();
      },
    },
    stat: {
      fromSanityRecord({
        _id,
        title,
        startDate,
        endDate,
        location: address,
        sessions,
      }) {
        return Event({
          _id,
          title,
          startDate,
          endDate,
          address,
          sessions,
        });
      },
    },
  }
);
