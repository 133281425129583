import groq from "groq";

export const ScheduleService = {
  fetchEventData() {
    const PROJECT_ID = process.env.SANITY_PROJECT_ID;
    const DATASET = process.env.SANITY_DATASET;
    const QUERY = encodeURIComponent(groq`
    *[_type == "event" && dateTime(endDate + "T23:59:00Z") >= dateTime(now())]{
      title,
      startDate,
      endDate,
      location,
      config,
      "sessions": *[_type == "eventSession" && references(^._id)]
        | order(startDate asc, startTime asc)
        {
          _id,
          title,
          startDate,
          startTime,
          endTime,
          description,
          location->,
          "presenters": presenters[]->name,
          maxAttendance,
          cost,
          registrationUrl,
      }
  }
`);
    return fetch(
      `https://${PROJECT_ID}.apicdn.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`
    ).then((res) => {
      return res.json();
    });
  },
};
