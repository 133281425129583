const reAlpha = /[A-Za-z]/;
const reCurrencyWithSymbol = /^\$\d+(?:\.\d\d)?$/;

/**
 * Add currency symbol to number or string representing money.
 *
 * Non-numeric strings and already-formatted will be returned as-is to support strings as-is like
 * "Free" or "Donations Accepted". Already formatted strings will also be returned as-is
 *
 * @param {string} amount The amount to format
 * @returns {string} The formatted currency string, the non-numeric string returned as-is, or `freeFallback` when `amount` === 0
 */
export const formatCurrency = (amount) => {
  if (reAlpha.test(amount) || reCurrencyWithSymbol.test(amount)) {
    return amount;
  }

  return "$".concat(amount);
};
