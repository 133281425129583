import { compose } from "~/lib/fn/mod.js";
import { tkns, words } from "~/lib/str/mod.js";
import { BookmarkIcon } from "~/ui/Images/mod.js";

import { withAppState } from "~/features/AppState/mod.js";
import { addBookmark, removeBookmark } from "../mod.js";

export const BookmarkButton = ({
  // Own Props
  itemTitle = "this",
  itemId = "0",
  className,
  isPressed = false,
  // AppState
  isBookmarkingEnabled,
  eventData,
}) => (
  <button
    type="button"
    className={tkns("btn btn-graphic", className)}
    hidden={!isBookmarkingEnabled.value}
    onClick={() => {
      if (!isPressed) {
        console.debug("[DEBUG] Add bookmark", itemId);
        addBookmark({ eventData }, itemId);
      } else {
        console.debug("[DEBUG] Remove bookmark", itemId);
        removeBookmark({ eventData }, itemId);
      }
    }}
    aria-pressed={String(isPressed)}
    aria-live="polite"
    // TODO Dynamic label based on bookmark state
    aria-label={words(
      isPressed ? "Unbookmark" : "Bookmark",
      `the ${itemTitle} session`
    )}
  >
    <BookmarkIcon />
  </button>
);

export default compose(withAppState)(BookmarkButton);
