import { useRef } from "react";
import { getDayOfWeek } from "~/lib/datetime/mod.js";
import { useEffectOnMounted } from "~/lib/hooks/mod.js";
import { capitalize } from "~/lib/str/mod.js";

const getTimeAtNearestHalfHour = (dateCtor = () => new Date()) => {
  const now = dateCtor();
  const minutes = now.getMinutes();
  now.setMinutes(minutes < 30 ? 0 : 30, 0, 0);
  return now;
};

export const DateJumpBar = ({ dates, id, a11yLabel }) => {
  /** @type {import('react').MutableRefObject<HTMLCollection> | undefined} */
  const sessionStartTimes = useRef();

  const handleNowClick = (ev) => {
    const now = getTimeAtNearestHalfHour();
    /** @type {HTMLTimeElement | undefined} */
    const nextEvent = Array.prototype.find.call(
      sessionStartTimes.current,
      (el) => Date.parse(el.dateTime) >= now
    );
    if (nextEvent) {
      const headerHeight = document.querySelector("#app > header").clientHeight;
      const dateHeadingHeight =
        document.querySelector(".date-heading").clientHeight;
      const scrollTarget = nextEvent.closest(".schedule-item");
      const scrollToY =
        scrollTarget.offsetTop - (headerHeight + dateHeadingHeight);
      window.scrollTo(0, scrollToY);
    }

    ev.preventDefault();
  };

  useEffectOnMounted(() => {
    sessionStartTimes.current = document.getElementsByClassName("js-startTime");
  });

  return (
    <nav className="date-jump-bar" id={id} aria-label={a11yLabel}>
      <ol>
        {dates.map((date, i) => {
          const id = "day".concat(String(i + 1));
          return (
            <li key={id}>
              <a href={"#".concat(id)}>
                <time dateTime={date.toISOString().split("T")[0]}>
                  {capitalize(getDayOfWeek(date))} {date.getDate()}
                </time>
              </a>
            </li>
          );
        })}

        <li>
          <a href="#" onClick={handleNowClick}>
            Now
          </a>
        </li>
      </ol>
    </nav>
  );
};
