import { Heading } from "~/ui/Typography/mod.js";

/**
 * @param {Object} props The component props
 * @param {import("./PhysicalAddress.js").PhysicalAddress} props.address The address data to render
 * @param {1|2|3|4|5|6} [props.headingLevel] The heading level for {@link props.address.name} if present
 * @returns
 */
export const PhysicalAddress = ({ headingLevel, address, className }) => (
  <div className="address">
    {address.name ? (
      <Heading headingLevel={headingLevel} className="address-title">
        {address.name}
      </Heading>
    ) : null}
    <p className="address-content">
      {address.street1}
      <br />
      {address.street2 ? (
        <>
          {address.street2}
          <br />
        </>
      ) : null}
      {address.locality}, {address.region} {address.postalCode}
    </p>
  </div>
);
