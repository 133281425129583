const validHeadingLevels = new Set([1, 2, 3, 4, 5, 6]);

/**
 * @param {object} props Component props
 * @param {import("preact").ComponentChild} props.children The component children
 * @param {1|2|3|4|5|6} props.headingLevel The level of the <h#> tag to render. If invalid or missing, renders a <div>
 * @param {string} [props.className] Optional className for the component
 * @param {string} [props.id] Optional id for the component
 * @returns
 */
export const Heading = ({ children, className, id, headingLevel }) => {
  const Component = validHeadingLevels.has(headingLevel)
    ? "h".concat(headingLevel)
    : "div";
  return (
    <Component className={className} id={id}>
      {children}
    </Component>
  );
};
