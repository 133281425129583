import { tkns } from "~/lib/str/mod.js";

export const SvgIcon = ({ children, width, height, hidden, className }) => (
  <span className={tkns("icon", className)} hidden={hidden}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      role="img"
    >
      {children}
    </svg>
  </span>
);
