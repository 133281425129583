const re = /\(\(([^)]+)\)\)/;

export const pluralize = (count, str) => {
  if (count < 0) {
    throw new Error(`count arg cannot be negative. ${count} given`);
  }

  return str.replace(re, (_, p1) => {
    const pieces = p1.split("|");
    const [zero, single, plural] =
      pieces.length === 1
        ? [pieces[0], "", pieces[0]]
        : pieces.length === 2
        ? [pieces[1], pieces[0], pieces[1]]
        : [pieces[2], pieces[0], pieces[1]];

    if (count === 0) {
      return zero;
    } else if (count === 1) {
      return single;
    } else {
      return plural;
    }
  });
};
