// import { getIanaTimezone } from "./timezones.js";

const timeFormattersByTimezone = new Map();

const createTimeFormatter = (ianaTimezone) =>
  new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "2-digit",
    timeZone: ianaTimezone,
  });

const getTimeFormatter = (ianaTimezone) => {
  const formatter = timeFormattersByTimezone.get(ianaTimezone);
  if (formatter) {
    return formatter;
  }

  const newFormatter = createTimeFormatter(ianaTimezone);
  timeFormattersByTimezone.set(ianaTimezone, newFormatter);
  return newFormatter;
};

const reWhitespace = /\s/g;
export const formatTime = (date, ianaTimezone) => {
  // const ianaTimezone = getIanaTimezone(timezoneAbbr);

  const formatter = getTimeFormatter(ianaTimezone);

  // NOTE For some reason, the server is outputting Narrow No-Break Space (8239) as whitespace while
  // the browser is outputting a plain space which is causing hydration mismatch
  return formatter.format(date).toLowerCase().replace(reWhitespace, " ");
};

const dateFormatter = new Intl.DateTimeFormat("en-US", {
  month: "long",
  day: "numeric",
});

export const formatDate = (date) => {
  // NOTE For some reason, the server is outputting Narrow No-Break Space (8239) as whitespace while
  // the browser is outputting a plain space which is causing hydration mismatch
  return dateFormatter.format(date).toLowerCase().replace(reWhitespace, " ");
};

const shortWeekDateFormatter = new Intl.DateTimeFormat("en-US", {
  weekday: "short",
});

const longWeekDateFormatter = new Intl.DateTimeFormat("en-US", {
  weekday: "long",
});

export const getDayOfWeek = (date, longName = false) => {
  // NOTE For some reason, the server is outputting Narrow No-Break Space (8239) as whitespace while
  // the browser is outputting a plain space which is causing hydration mismatch
  return (longName ? longWeekDateFormatter : shortWeekDateFormatter)
    .format(date)
    .toLowerCase()
    .replace(reWhitespace, " ");
};
