import { Layout } from "~/ui/Layout/mod.js";

import { capitalize } from "~/lib/str/mod.js";
import { formatDate, getDayOfWeek } from "~/lib/datetime/mod.js";
import { DateJumpBar, EventSessionEntry } from "~/features/Schedule/mod.js";
import { Address } from "~/features/Location/mod.js";
import { Heading } from "~/ui/Typography/mod";

/**
 * @param {Object} props Component props
 * @param {import('~/features/Schedule/mod.js').Event} props.event The event data
 * @returns A rendered component
 */
const SchedulePage = ({ event }) => {
  const footer = <DateJumpBar dates={event.getEventDates()} />;
  const startDate = event.getLocalStartDate();
  const endDate = event.getLocalEndDate();
  return (
    <Layout pageTitle={event.title} footer={footer} meta={{ "event-year": event.getEventYear() }}>
      <section>
        <p className="event-date">
          {capitalize(getDayOfWeek(startDate, true))}
          {", "}
          {capitalize(formatDate(startDate))} &ndash; {capitalize(getDayOfWeek(endDate, true))}
          {", "}
          {capitalize(formatDate(endDate))} {endDate.getFullYear()}
        </p>

        <Address address={event.address} headingLevel={2} />
      </section>

      {event.sessions.map(([dateYmd, date, sessions], i) => (
        <section
          className="schedule-date-group"
          key={`schedule-date-group-${dateYmd}`}
        >
          {/* NOTE id=day# serves as jump point for nav anchors. Using a position: sticky;
          element like .date-heading causes weird scroll issues */}
          <div id={"day".concat(String(i + 1))} />
          <Heading headingLevel={3} className="date-heading">
            {capitalize(getDayOfWeek(date, true))}
            {", "}
            {capitalize(formatDate(date))}
          </Heading>

          {sessions.map((session) => (
            <EventSessionEntry
              key={session._id}
              className="schedule-item"
              eventSession={session}
            />
          ))}
        </section>
      ))}
    </Layout>
  );
};

export { SchedulePage };
