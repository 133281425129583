import { useRef } from "react";
import Head from "next/head.js";
import Link from "next/link.js";
import { useSearchParams } from "next/navigation.js";
import { Heading } from "~/ui/Typography/Heading.jsx";
import {
  BookmarkIcon,
  BookmarkIconSymbol,
  ChevronIcon,
  ChevronIconSymbol,
} from "~/ui/Images/mod.js";
import { useBooleanState } from "~/lib/component/mod.hooks.js";
import { tkns } from "~/lib/str/mod.js";
import { compose } from "~/lib/fn/compose.js";
import { withAppState } from "~/features/AppState/mod.js";

export const Layout = ({
  // OWN
  pageTitle,
  footer = null,
  className,
  children,
  meta = {},
  // AppState
  hasRuntimeJs,
}) => {
  const [menuIsOpen, { toggle: toggleMenu }] = useBooleanState(false);
  const menuDrawerEl = useRef();

  const urlParams = useSearchParams();
  const filterIsBookmarks = urlParams.get("filter") === "bookmarks";
  const rootClassName = tkns(className, {
    js: hasRuntimeJs.value,
    "filter-bookmarks": filterIsBookmarks,
  });


  return (
    <div id="app" className={rootClassName}>
      <Head>
        <title>{`${pageTitle} Schedule App`}</title>
        <link rel="icon" href="/CAlogo.svg" type="image/svg+xml" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {Object.keys(meta).length > 0
          ? Object.entries(meta).map(([name, content]) => (
              <meta key={name} name={name} content={content} />
            ))
          : null}
      </Head>
      <svg width="0" height="0">
        <BookmarkIconSymbol />
        <ChevronIconSymbol />
      </svg>
      <header>
        <div className="track">
          <div className="track-item">
            <Heading headingLevel={1} className="main-title">
              <a href="https://www.clockworkalchemy.com/" target="mainsite">
                <img
                  src="/2024-aetheric-ocean-text.png"
                  alt={pageTitle}
                  className="main-title-image"
                />
              </a>
            </Heading>
          </div>

          <div
            className="track-item filter-status-icons"
            hidden={menuIsOpen}
            aria-hidden={String(menuIsOpen)}
          >
            <BookmarkIcon
              size="16"
              className="filter-status-icon"
              hidden={!filterIsBookmarks}
            />
          </div>
          <div className="track-item menu">
            <button
              type="button"
              className="btn btn-graphic menu-button"
              disabled={!hasRuntimeJs.value}
              aria-expanded={String(menuIsOpen)}
              aria-controls="menu-drawer"
              onClick={() => {
                toggleMenu();
              }}
            >
              <ChevronIcon size="16" className="menu-button-icon" />
              <img
                src="/2024-ca-logo.png"
                alt="Clockwork Alchemy"
                className="logo"
              />
            </button>
          </div>
        </div>
        <div
          className="menu-drawer"
          id="menu-drawer"
          ref={menuDrawerEl}
          data-collapsed={String(!menuIsOpen)}
          hidden={!menuIsOpen}
        >
          <section className="filters">
            <h2 className="filters-title">Show:</h2>
            <menu className="filters-list">
              <li>
                {/* NOTE href="" means to link to the current path with no query params or hashes */}
                <Link
                  href=""
                  className={tkns({
                    "filter-current": !Array.from(urlParams.keys()).length,
                  })}
                >
                  All
                </Link>
              </li>
              <li>
                <Link
                  href="?filter=bookmarks"
                  className={tkns({
                    "filter-current": urlParams.get("filter") === "bookmarks",
                  })}
                >
                  Bookmarked
                  <BookmarkIcon size="16" className="filter-action-icon" />
                </Link>
              </li>
            </menu>
          </section>
        </div>
      </header>
      <main>
        <a id="top" hidden></a>
        {children}
      </main>
      <footer>{footer}</footer>
    </div>
  );
};

export default compose(withAppState)(Layout);
