export const addBookmark = ({ eventData }, bookmarkId) => {
  eventData.bookmarks.value = {
    ...eventData.bookmarks.value,
    [bookmarkId]: true,
  };
};

export const removeBookmark = ({ eventData }, bookmarkId) => {
  const newBookmarks = { ...eventData.bookmarks.value };
  delete newBookmarks[bookmarkId];
  eventData.bookmarks.value = newBookmarks;
};
