const reYmd = /^\s*(\d{4})-(\d\d)-(\d\d)\s*$/;

const zeroPad = (value) => String(value).padStart(2, "0");

export const CalendarDate = (dateString = "") => {
  const [year, month, date] = String(dateString).match(reYmd).slice(1, 4).map(Number);

  return {
    toString() {
      return `${year}-${zeroPad(month)}-${zeroPad(date)}`;
    },
    valueOf() {
      return this.toDate().getTime();
    },
    [Symbol.toPrimitive](hint) {
      return hint === "string" ? this.toString() : this.valueOf();
    },
    toDate() {
      return new Date(year, month - 1, date);
    },
    addDay(count = 1) {
      const copy = () => CalendarDate(this);

      if (Number.isNaN(count)) {
        return copy();
      }

      const c = Math.max(count, 0);
      if (c === 0) {
        return copy();
      }

      const calcDate = new Date(year, month - 1, date + c);
      const [newYear, newMonth, newDate] = [
        calcDate.getFullYear(),
        calcDate.getMonth() + 1,
        calcDate.getDate(),
      ];
      return CalendarDate(`${newYear}-${zeroPad(newMonth)}-${zeroPad(newDate)}`);
    },
  };
};
