import { SvgIcon } from "./SvgIcon.jsx";

const id = "chevron-icon";

export const ChevronIconSymbol = () => (
  <symbol id={id} viewBox="0 0 16 16">
    <polyline
      fill="none"
      stroke="currentColor"
      strokeWidth="2px"
      points="15,11 8,5 1,11"
    />
  </symbol>
);

export const ChevronIcon = ({ className, size = "24", hidden = false }) => (
  <SvgIcon className={className} width={size} height={size} hidden={hidden}>
    <use xlinkHref={`#${id}`} />
  </SvgIcon>
);
