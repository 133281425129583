import { pluralize } from '~/lib/str/mod.js';

const timeUnits = [
  [1000, 'second((s))'],
  [60, 'minute((s))'],
  [60, 'hour((s))'],
];

export const duration = (milliseconds) => {
  const [time, label] = timeUnits.reduce(([currTime, currLabel], [unit, label]) => {
    if (currTime >= unit) {
      return [currTime / unit, label];
    }

    return [currTime, currLabel];
  }, [milliseconds, 'millisecond((s))']);

  return `${time} ${pluralize(time, label)}`;
};
