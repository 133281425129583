import { wrapComponentName } from "~/lib/component/mod.js";

export const hydrateProps = (propMap) => (Component) => {
  const HydratedProps = (ownProps) => {
    const hydratedProps = Object.entries(propMap).reduce(
      (final, [propName, Ctor]) => (
        (final[propName] = Ctor(ownProps[propName])), final
      ),
      {}
    );

    return <Component {...ownProps} {...hydratedProps} />;
  };

  HydratedProps.displayName = wrapComponentName(Component, HydratedProps);

  return HydratedProps;
};
