import { duration } from "~/lib/datetime/mod.js";
import { compose } from "~/lib/fn/mod.js";
import { pluralize, tkns } from "~/lib/str/mod.js";
import { formatCurrency } from "~/lib/num/mod.js";

import { withConfig } from "~/features/Config/mod.js";
import { BookmarkButton } from "~/features/Bookmarks/mod.ui.js";

import { Heading, Text } from "~/ui/Typography/mod.js";
import { withAppState } from "../AppState/mod.js";
import { useComputed } from "@preact/signals-react";

const mapConfigToProps = {
  displayRoomStyle: "locationDisplay",
};

/**
 * A Schedule Entry component
 *
 * @param {Object} props Component props
 * @param {import("./EventSession.js").EventSession} props.eventSession The schedule entry data
 * @param {1|2|3|4|5|6} [props.headingLevel=3] The top heading level for the entry
 * @param {import('~/')} [props.displayRoomStyle] A CSS class to apply to the root element
 * @param {string} [props.className] A CSS class to apply to the root element
 * @returns A rendered component
 */
const EventSessionEntry = ({
  eventSession,
  headingLevel = 3,
  displayRoomStyle = "venue",
  className,
  eventData,
}) => {
  const isBookmarked = useComputed(
    () => eventData.bookmarks.value[eventSession._id] === true
  );

  return (
    <article
      className={tkns(className, { "is-bookmarked": isBookmarked.value })}
    >
      <header className="schedule-item-header">
        <Heading headingLevel={headingLevel} className="schedule-item-heading">
          {eventSession.title}
        </Heading>
        <BookmarkButton
          itemTitle={eventSession.title}
          itemId={eventSession._id}
          isPressed={isBookmarked.value}
          className="schedule-item-bookmark-action"
        />
      </header>
      <Text as="dl" className="schedule-item-detail">
        <div>
          <dt>{pluralize(eventSession.presenters.length, "Presenter((s))")}</dt>
          <dd>{eventSession.presenters.join(", ")}</dd>
        </div>
        <div>
          <dt>When</dt>
          <dd>
            <time
              className="js-startTime"
              dateTime={eventSession.getLocalStartDateTime().toISOString()}
            >
              {eventSession.startTime.to12Hours()}
            </time>
            &ndash;
            <time dateTime={eventSession.getLocalEndDateTime().toISOString()}>
              {eventSession.endTime.to12Hours()}
            </time>{" "}
            ({duration(eventSession.getDurationInMilliseconds())})
          </dd>
        </div>
        <div>
          <dt>Location</dt>
          <dd>{eventSession.location.getLocationInfo(displayRoomStyle)}</dd>
        </div>
        {eventSession.cost ? (
          <div>
            <dt>Cost</dt>
            <dd>
              {formatCurrency(eventSession.cost)}{" "}
              {eventSession.registrationUrl ? (
                <a
                  href={eventSession.registrationUrl}
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  Register
                </a>
              ) : null}
            </dd>
          </div>
        ) : null}
        {eventSession.maxAttendance ? (
          <div>
            <dt>Max Attendance</dt>
            <dd>{eventSession.maxAttendance}</dd>
          </div>
        ) : null}
      </Text>

      <Text>{eventSession.description}</Text>
    </article>
  );
};

export { EventSessionEntry };
export default compose(
  withConfig(mapConfigToProps),
  withAppState
)(EventSessionEntry);
