import { Event } from "~/features/Schedule/mod.js";
import { SchedulePage } from "~/pages/SchedulePage/mod.js";
import { hydrateProps } from "~/ui/Hoc/mod.js";
import { ScheduleService } from "~/features/Schedule/mod.js";

export default hydrateProps({ event: Event.fromSanityRecord })(SchedulePage);

export async function getStaticProps() {
  const { result } = await ScheduleService.fetchEventData();

  // Split out the config data from the event data
  const { config = {}, ...event } = result[0];
  // console.table(event.sessions, ["registrationUrl"]);

  return {
    props: {
      event,
      config,
    },
  };
}
