import { Model } from "~/lib/data/mod.js";
import { convertIso8601UtcToLocalDate, Time } from "~/lib/datetime/mod.js";
import { VenueLocation } from "~/features/Location/mod.js";

// Reference for 24:00 in milliseconds
const midnightMs = Time("00:00").toMilliseconds(true);

/**
 * A physical address data structure
 *
 * @typedef {Object} EventSession
 * @prop {string} [_id] The record ID
 * @prop {string} title The title
 * @prop {string} category The category
 * @prop {Date} startDate The date of the session
 * @prop {string} startDateYmd The date of the session in YYYY-MM-DD
 * @prop {import('~/lib/datetime/mod.js').Time} startTime The start time in 24-hour time
 * @prop {import('~/lib/datetime/mod.js').Time} endTime The end time in 24-hour time
 * @prop {import{'~/features/Location/mod.js'}.VenueLocation} location The location of the event session
 * @prop {string} description The description
 * @prop {string[]} presenters A list of presenter names
 * @prop {number} [maxAttendance] The maximum attendance, if applicable
 * @prop {string} [cost] The cost of the session, if applicable
 * @prop {URL} [registrationUrl] The URL for registering for the session
 * @prop {(this: EventSession) => number} getDurationInMilliseconds Get the duration of the session in milliseconds
 * @prop {(this: EventSession) => Date} getLocalStartDateTime
 * @prop {(this: EventSession) => Date} getLocalEndDateTime
 */

/**
 * Model for physical address data
 *
 * @param {Object} data The raw event session data
 * @returns {EventSession}
 */
export const EventSession = Model.extend(
  function EventSession({
    _id,
    title,
    category,
    startDate,
    startTime,
    endTime,
    location,
    description,
    presenters,
    maxAttendance,
    cost,
    registrationUrl,
  }) {
    return {
      _id,
      title,
      category,
      startDate: convertIso8601UtcToLocalDate(startDate),
      startDateYmd: startDate,
      startTime: Time(startTime),
      endTime: Time(endTime),
      location: VenueLocation(location),
      description,
      presenters,
      maxAttendance,
      cost,
      registrationUrl:
        registrationUrl && registrationUrl !== "FIXME"
          ? new URL(registrationUrl)
          : undefined,
    };
  },
  {
    proto: {
      /**
       * @this {EventSession}
       */
      getDurationInMilliseconds() {
        const startMs = this.startTime.toMilliseconds();
        const endMs = this.endTime.toMilliseconds(true);

        // If the end time is earlier/less than the start time, assume it is scheduled over midnight
        return startMs < endMs ? endMs - startMs : midnightMs - startMs + endMs;
      },
      /**
       * @this {EventSession}
       */
      getLocalStartDateTime() {
        return convertIso8601UtcToLocalDate(
          this.startDateYmd,
          this.startTime.to24Hours()
        );
      },
      /**
       * @this {EventSession}
       */
      getLocalEndDateTime() {
        return convertIso8601UtcToLocalDate(
          this.startDateYmd,
          this.endTime.to24Hours()
        );
      },
    },
  }
);
