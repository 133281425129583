// const reTimeString = /^\s*(\d\d):(\d\d)(?:\s*([aA][mM]|[pP][mM]))?\s*$/;

import { padDateTimeSegment } from "./padDateTimeSegment.js";

const MINUTE_IN_MILLISECONDS = 60 * 1000;
const HOUR_IN_MILLISECONDS = 60 * MINUTE_IN_MILLISECONDS;

// NOT 24-hour match
const reTimeString = /^\s*(\d\d):(\d\d)/;

/**
 * A data type for working with time values (without date info)
 *
 * @todo Add support for second and milliseconds
 *
 * @typedef {Object} Time
 * @prop {number} hour The number of hours in 24-hour time
 * @prop {number} minutes The number of minutes
 * @prop {() => string} to12Hours Output the time in 12-hour time
 * @prop {() => string} to24Hours Output the time in 24-hour time
 * @prop {(midnightIsHigh: boolean) => number} toMilliseconds Convert the time to milliseconds. If `midnightIsHigh = true`, treat midnight hour as 24 instead of 0
 */

/**
 * A string wrapper to help work with time values
 *
 * @todo Add support for 12-hour `timeString` arg
 *
 * @param {string} timeString A time string in 24-hour time
 * @returns {Time} A Time instance
 */
export const Time = (timeString) => {
  /**
   * @var {[number, number]} parts
   */
  const [hour, minute] = timeString.match(reTimeString).slice(1).map(Number);

  return {
    get hours() {
      return hour;
    },
    get minutes() {
      return minute;
    },
    to12Hours() {
      if (hour === 0 && minute === 0) {
        return "Midnight";
      }

      if (hour === 12 && minute === 0) {
        return "Noon";
      }

      const hr = hour === 0 ? 12 : hour <= 12 ? hour : hour % 12;
      const min = padDateTimeSegment(minute);
      const ap = hour < 12 ? "am" : "pm";

      return `${hr}:${min} ${ap}`;
    },
    to24Hours() {
      return `${padDateTimeSegment(hour)}:${padDateTimeSegment(minute)}`;
    },
    toMilliseconds(midnightIsHigh = false) {
      return (
        (midnightIsHigh && hour === 0 ? 24 : hour) * HOUR_IN_MILLISECONDS +
        minute * MINUTE_IN_MILLISECONDS
      );
    },
    valueOf() {
      return this.toMilliseconds();
    },
    toString() {
      return [hour, minute].map(padDateTimeSegment).join(":");
    },
    [Symbol.toPrimitive](hint) {
      // TODO unify stringification with .toString()
      if (hint === "string") {
        return this.to12Hours();
      }

      return this.valueOf();
    },
  };
};
