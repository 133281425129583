import { SvgIcon } from "./SvgIcon.jsx";

const id = "bookmark-icon";

export const BookmarkIconSymbol = () => (
  <symbol id={id} viewBox="0 0 24 24">
    <polygon
      points="4,1 20,1 20,23 12,16 4,23"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </symbol>
);

export const BookmarkIcon = ({ className, size = "24", hidden = false }) => (
  <SvgIcon className={className} width={size} height={size} hidden={hidden}>
    <use xlinkHref={`#${id}`} />
  </SvgIcon>
);
