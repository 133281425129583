/**
 * Convert an ISO 8601 date or datetime string to a Date in local timezone
 *
 * Because dates are usually stored in databases in UTC timezone, displaying them for a user will
 * end up being offset to their viewing timezone. Dates for the schedule are defined for the local
 * timezone but stored as UTC so we need to take the UTC date/datetime string and convert it to a
 * local timezone using the `new Date(number, number, number, number, number)` method.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#individual_date_and_time_component_values
 *
 * @param {string} dateYmd The date in YYYY-MM-DD format
 * @param {string} timeHms The time in HH:MM:SS format (with seconds optional)
 * @returns {Date} The UTC date converted to local timezone
 */
export const convertIso8601UtcToLocalDate = (dateYmd, timeHms = "00:00:00") =>
  new Date(`${dateYmd}T${timeHms}-0700`);
