import { Model } from "~/lib/data/mod.js";
import { formatOrdinal } from "~/lib/num/mod.js";

/**
 * A physical address data structure
 *
 * @typedef {Object} VenueLocation
 * @prop {string} _id The ID of the VenueLocation record
 * @prop {string} venueName The name of the location used by the venue
 * @prop {string} eventName The name of the location used by the event
 * @prop {string} floor The floor where the location is. Often a number but sometimes e.g "Mezzanine"
 */

/**
 * Model for data about locations within the venue
 *
 * @param {Object} data The raw venue location data
 * @returns {VenueLocation}
 */
export const VenueLocation = Model.extend(
  function VenueLocation({ _id, venueName, eventName, floor }) {
    return { _id, venueName, eventName, floor };
  },
  {
    proto: {
      /**
       * Output the room + floor info
       *
       * @param {"event"|"venue"} namePreference The preferred display of the room names
       * @returns The room name + floor
       */
      getLocationInfo(namePreference) {
        const floorInfo = ((n) =>
          /^\d+$/.test(n) ? formatOrdinal(n).concat(" floor") : n)(this.floor);
        const roomDisplay =
          this[namePreference === "event" ? "eventName" : "venueName"];

        return `${roomDisplay} (${floorInfo})`;
      },
    },
  }
);
