const englishOrdinalRules = new Intl.PluralRules("en-US", { type: "ordinal" });
const suffixes = {
  one: "st",
  two: "nd",
  few: "rd",
  other: "th",
};

/**
 * Convert a number value to ordinal string
 *
 * @link https://stackoverflow.com/a/57518703
 *
 * @param {number|string} number The number to format as ordinal
 * @returns A string of the number in ordinal form
 */
export const formatOrdinal = (number) => {
  const n = Number(number);
  if (Number.isNaN(n)) {
    throw new TypeError(
      `formatOrdinal(): Unable to convert ${number} to a number.`
    );
  }

  const category = englishOrdinalRules.select(n);
  const suffix = suffixes[category];
  return String(n).concat(suffix);
};
